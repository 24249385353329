<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col lg="12" sm="12" v-if="deviceTypology != 'Blast Chiller'">
        <alerts-info-header />
        <device-info-generic-banner />
      </b-col>
       <b-col lg="12" sm="12" v-else-if="deviceTypology == 'Blast Chiller'">
        <alerts-info-header />
        <device-info-abatedor-banner />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12" sm="12">
        <alerts-info />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import AlertsInfoHeader from "./AlertsInfoHeader.vue";
import AlertsInfo from "./AlertsInfo.vue";
import DeviceInfoGenericBanner from "../generic-device/DeviceInfoGenericBanner.vue";
import DeviceInfoAbatedorBanner from "../device/DeviceInfoAbatedorBanner.vue";

export default {
  components: {
    BRow,
    BCol,
    AlertsInfoHeader,
    AlertsInfo,
    DeviceInfoGenericBanner,
    DeviceInfoAbatedorBanner
  },
  data() {
    return {
      data: {},
    };
  },
  computed:{
    deviceTypology()
    {
      return this.$store.state.devices.currentDevice.typology; 
    }
  },
  created() {
    this.$store.dispatch("devices/getSingleDevice", {
      context: this,
      id: this.$route.params.id,
    });
    this.$store.dispatch("devices/getSingleDeviceRoles", {
      context: this,
      id: this.$route.params.id,
    });
    this.$store.dispatch("devices/getGenericDevicelMetric", {
      context: this,
      id: this.$route.params.id,
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-alerts.scss";
@import "@core/scss/vue/pages/page-device.scss";
</style>
